import request from "./request";

// 获取投诉列表
export function getComplaintList(query: any) {
  return request({
    url: "/complaint/list",
    method: "get",
    params: query,
  });
}

// 获取投诉详情
export function getComplaintDetails(query: any) {
  return request({
    url: "/complaint/details",
    method: "get",
    params: query,
  });
}

// 获取处理历史
export function getComplaintHistory(query: any) {
  return request({
    url: "/complaint/history",
    method: "get",
    params: query,
  });
}

// 处理投诉
export function complaintResponse(
  complaint_id: string,
  mchid: string,
  response_content: string,
  response_images: string[]
) {
  const formData = new FormData();
  formData.append("complaint_id", complaint_id);
  formData.append("mchid", mchid);
  formData.append("response_content", response_content);
  formData.append("response_images", JSON.stringify(response_images));

  return request({
    url: "/complaint/response",
    method: "POST",
    data: formData,
  });
}

// 处理完成
export function complaintComplete(complaint_id: string, mchid: string) {
  const formData = new FormData();
  formData.append("complaint_id", complaint_id);
  formData.append("mchid", mchid);
  return request({
    url: "/complaint/complete",
    method: "POST",
    data: formData,
  });
}

// 获取投诉列表
export function imageUpload(mchid: string, file: File) {
  const formData = new FormData();
  formData.append("mchid", mchid);
  formData.append("img", file);

  return request({
    url: "/complaint/image/upload",
    method: "POST",
    data: formData,
  });
}

// 获取商户
export function getMerchant() {
  return request({
    url: "/merchant",
    method: "get",
  });
}

// 删除商户
export function removeMerchant(id: string) {
  return request({
    url: "/merchant",
    method: "delete",
    params: {
      id,
    },
  });
}

// 添加商户
export function appendMerchant(data: any) {
  const formData = new FormData();
  formData.append("mchid", data.mchid);
  formData.append("cert_serial_no", data.cert_serial_no);
  formData.append("apiv3_key", data.apiv3_key);
  formData.append("appid", data.appid);
  formData.append("private_key", data.private_key);
  formData.append("remarks", data.remarks);

  return request({
    url: "/merchant",
    method: "post",
    data: formData,
  });
}

// 启用邮箱
export function NotifySet(data: any) {
  const formData = new FormData();
  formData.append("mchid", data.mchid);
  formData.append("url", data.url);

  return request({
    url: "/notify/set",
    method: "post",
    data: formData,
  });
}

// 取消邮箱
export function NotifyRemove(data: any) {
  const formData = new FormData();
  formData.append("mchid", data.mchid);
  return request({
    url: "/notify/set",
    method: "DELETE",
    data: formData,
  });
}

// 登录
export function Login(password: any) {
  const formData = new FormData();
  formData.append("password", password);
  return request({
    url: "/login",
    method: "POST",
    data: formData,
  });
}
