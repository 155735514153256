import { Login } from "@/api";
import { Component, Ref, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class LoginView extends Vue {
  spinning = false;

  password: string = "";

  mounted() {}

  async login() {
    if (!this.password) {
      this.$message.error("请输入密码");
      return;
    }
    this.spinning = true;
    const res: any = await Login(this.password);
    // console.log(res);
    this.spinning = false;

    if (res.data.code === 200) {
      this.$message.success("登录成功");
      this.$router.push("/index");
    } else {
      this.$message.error(res.data.data);
    }
  }
}
