





















































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class MainView extends Vue {
  current = ["mail"];
  openKeys = ["sub1"];

  created() {}

  handleClick(e: any) {
    if (this.$route.path !== e.key) {
      this.$router.push(e.key);
    }
  }
  titleClick(e: any) {
    // console.log("titleClick", e);
  }
}
