import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import IndexView from "@/views/index-view/index-view.vue";
import MainView from "@/MainView.vue";
import LoginView from "@/views/login-view/login-view.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "MainView",
    component: MainView,
    redirect: "/index",
    children: [
      {
        path: "/index",
        name: "IndexView",
        component: IndexView,
      },
      {
        path: "/setting",
        name: "Setting",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/setting-view/setting-view.vue"
          ),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.name) {
    next("/");
  }

  const token = Vue.$cookies.get("TOKEN");
  const time = Vue.$cookies.get("TIME");
  if (token && time) {
    next();
  } else {
    // console.log(to);
    if (to && to.path === "/login") {
      next();
    } else {
      next("/login");
    }
  }
});

export default router;
