import axios, { AxiosInstance } from "axios";
import { HttpResponse } from "./HttpResponse";
import VueCookies from "vue-cookies";
import Vue from "vue";
import { message } from "ant-design-vue";
Vue.use(VueCookies);

const service: AxiosInstance = axios.create({
  baseURL: "/api", // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 40000, // request timeout
});

service.interceptors.request.use(
  (config: any) => {
    // do something before request is sent
    // 以下API不需要认证
    // const noAuthApi = ["/Auth/login"];
    // if (noAuthApi.indexOf(config.url) === -1) {
    //   const Authorization = Vue.$cookies.get("Authorization");
    //   const UserId = Vue.$cookies.get("UserId");
    //   if (Authorization) config.headers.Authorization = Authorization;
    //   if (UserId) config.headers.UserId = UserId;
    // }

    return config;
  },
  (error: Error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// 响应体拦截器
service.interceptors.response.use(
  (response: any) => {
    // const res: HttpResponse = response.data;
    // 如果错误码不是0, 则请求失败
    // if (res.code !== 0) {
    //   message.error(res.msg || "异常错误");
    //   return Promise.reject(new Error(res.msg || "异常错误"));
    // } else {
    //   // 检查认证信息
    //   const token = response.headers.authorization;
    //   if (token) {
    //     Vue.$cookies.set("Authorization", token, 60 * 60 * 24 * 7);
    //   }
    //   return res.data as any;
    // }
    return response as any;
  },
  (error: any) => {
    if (error.message == "Network Error") {
      error = new Error("请求失败，请检查网络连接");
    }

    message.error(error.message);
    return Promise.reject(error);
  }
);

export default service;
