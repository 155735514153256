import {
  complaintComplete,
  complaintResponse,
  getComplaintDetails,
  getComplaintHistory,
  getComplaintList,
  imageUpload,
} from "@/api";
import moment, { Moment, relativeTimeThreshold } from "moment";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class IndexView extends Vue {
  spinning = false;

  form: any = {
    complaint_time: [moment().subtract(28, "day"), moment()], // 投诉时间
    // complaint_state: "state1", // 投诉状态
    // user_message_status: "state1", // 用户留言状态
    // user_complaints_count: "state1", // 用户投诉次数
    // timeout_status: "state1", // 超时状态
    // complaint_number: "", // 投诉单号
    // merchant_order_number: "", // 商户订单号
    // wechat_payment_order_number: "", // 微信支付订单号
  };

  locale = {
    filterConfirm: "确定",
    filterReset: "重置",
    emptyText: "暂无数据",
  };

  columns = [
    {
      title: "投诉单号",
      dataIndex: "complaint_id",
      key: "complaint_id",
      ellipsis: true,
    },
    {
      title: "投诉时间",
      dataIndex: "complaint_time",
      key: "complaint_time",
      scopedSlots: { customRender: "complaint_time" },
      ellipsis: true,
    },
    {
      title: "remark",
      dataIndex: "remark",
      key: "remark",
      ellipsis: true,
    },
    {
      title: "投诉详情",
      dataIndex: "complaint_detail",
      key: "complaint_detail",
      ellipsis: true,
    },
    {
      title: "投诉单状态",
      dataIndex: "complaint_state",
      key: "complaint_state",
      scopedSlots: { customRender: "complaint_state" },
      ellipsis: true,
      filters: [
        {
          text: "待处理",
          value: "PENDING",
        },
        {
          text: "处理中",
          value: "PROCESSING",
        },
        {
          text: "已处理完成",
          value: "PROCESSED",
        },
      ],
      onFilter: (value: any, record: any) => record.complaint_state === value,
    },
    // {
    //   title: "投诉人联系方式",
    //   dataIndex: "payer_phone",
    //   key: "payer_phone",
    //   ellipsis: true,
    // },
    {
      title: "投诉单是否已全额退款",
      dataIndex: "complaint_full_refunded",
      key: "complaint_full_refunded",
      scopedSlots: { customRender: "complaint_full_refunded" },
      ellipsis: true,
    },
    {
      title: "问题描述",
      dataIndex: "problem_description",
      key: "problem_description",
      ellipsis: true,
    },
    {
      title: "是否有待回复的用户留言",
      dataIndex: "incoming_user_response",
      key: "incoming_user_response",
      scopedSlots: { customRender: "incoming_user_response" },
      ellipsis: true,
    },

    {
      title: "用户投诉次数",
      dataIndex: "user_complaint_times",
      key: "user_complaint_times",
      ellipsis: true,
    },

    {
      title: "操作",
      key: "action",
      scopedSlots: { customRender: "action" },
      // ellipsis: true,
    },
  ];

  data: any = [];

  handleModal = false;
  confirmLoading = false;
  confirmDisabled = false;
  handleItem: any = {};

  fileList: any[] = [];
  imageList: any[] = [];
  reply_text: string = "";

  historyLine: any[] = [];
  historyModal = false;
  historyModalSpinning = false;

  pendingNum: number = 0;
  processingNum: number = 0;

  mounted() {
    const { complaint_id, mchid } = this.$route.query;
    if (complaint_id && mchid) {
      this.showHandleModal({ complaint_id, mchid });
    }

    if (window.screen.width < 800) {
      // 适配移动端
      this.columns = this.columns.filter((item: any) => {
        const displayColumns = ["投诉单号", "投诉单状态", "操作"];
        return displayColumns.includes(item.title);
      });
    }

    this.getComplainsList();
  }

  disabledDate(current: any) {
    return current > moment().endOf("day");
  }

  // 获取投诉列表
  async getComplainsList() {
    // console.log(this.form);
    if (this.form.complaint_time.length <= 0) {
      return;
    }
    const startDate: Moment = this.form.complaint_time[0] as Moment;
    const endDate: Moment = this.form.complaint_time[1] as Moment;
    const limitDate: Moment = moment(startDate).add(29, "day");
    if (limitDate.isBefore(endDate)) {
      this.$message.error("时间跨度不能超过30天");
      return;
    }
    const begin_date = startDate.format("YYYY-MM-DD");
    const end_date = endDate.format("YYYY-MM-DD");
    this.spinning = true;
    const res: any = await getComplaintList({ begin_date, end_date });
    this.spinning = false;
    // console.log(res);
    if (res.data.code !== 200) {
      this.$message.error("获取投诉列表失败");
      return;
    }
    let list: any[] = [];
    Object.keys(res.data.data).forEach((key: string) => {
      // console.log(res.data.data[key]);
      if (res.data.data[key].code === 200) {
        list.push(
          ...res.data.data[key].message.data.map((item: any) => {
            item.mchid = key;
            item.remark = res.data.data[key].remark;
            return item;
          })
        );
      }
    });

    this.data = list
      .map((item: any, index: number) => {
        const newItem = JSON.parse(JSON.stringify(item));
        newItem.key = index;
        return newItem;
      })
      .sort((a: any, b: any) => {
        if (moment(a.complaint_time).isBefore(moment(b.complaint_time))) {
          return 1;
        } else {
          return -1;
        }
      });

    this.pendingNum = this.data.filter(
      (item: any) => item.complaint_state === "PENDING"
    ).length;
    this.processingNum = this.data.filter(
      (item: any) => item.complaint_state === "PROCESSING"
    ).length;

    // console.log(this.data);
  }

  formatDate(date: string) {
    return moment(date).format("YYYY-MM-DD");
  }

  formatTime(date: string) {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }

  formatState(state: string) {
    const states: any = {
      PENDING: "待处理",
      PROCESSING: "处理中",
      PROCESSED: "已处理完成",
    };
    return states[state];
  }

  // 查看详情
  async showHandleModal(item: any) {
    await this.getDetails(item);
    this.handleModal = true;
  }

  // 获取详情
  async getDetails(item: any) {
    this.handleItem = {};
    this.spinning = true;
    const { complaint_id, mchid } = item;
    const res: any = await getComplaintDetails({ complaint_id, mchid });
    // console.log(res);
    this.spinning = false;

    const message = res.data.data.message;
    message.mchid = mchid;

    if (this.handleItem.complaint_id !== message.complaint_id) {
      this.fileList = [];
      this.imageList = [];
      this.reply_text = "";
    }

    this.handleItem = message;
  }

  // 查看处理历史
  async showHistoryModal(item: any) {
    // console.log(item);
    this.handleItem = {};
    this.historyLine = [];
    this.spinning = true;
    const { complaint_id, mchid } = item;
    const res: any = await getComplaintHistory({ complaint_id, mchid });
    // console.log(res);
    this.spinning = false;

    this.historyLine = res.data.data.message.data;
    // console.log(this.historyLine);

    await this.getDetails(item);

    this.historyModal = true;
  }

  formatOperateType(type: string) {
    const map: any = {
      USER_CREATE_COMPLAINT: "用户提交投诉",
      USER_CONTINUE_COMPLAINT: "用户继续投诉",
      USER_RESPONSE: "用户留言",
      PLATFORM_RESPONSE: "平台留言",
      MERCHANT_RESPONSE: "商户留言",
      MERCHANT_CONFIRM_COMPLETE: "商户申请结单",
      COMPLAINT_FULL_REFUNDED: "投诉单全额退款",
      USER_CREATE_COMPLAINT_SYSTEM_MESSAGE: "用户提交投诉系统通知",
      COMPLAINT_FULL_REFUNDED_SYSTEM_MESSAGE: "投诉单全额退款系统通知",
      USER_CONTINUE_COMPLAINT_SYSTEM_MESSAGE: "用户继续投诉系统通知",
      MERCHANT_CONFIRM_COMPLETE_SYSTEM_MESSAGE: "商户申请结单系统通知",
      USER_REVOKE_COMPLAINT: "用户主动撤诉",
      PLATFORM_HELP_APPLICATION: "平台问询",
      USER_APPLY_PLATFORM_HELP: "申请协助",
      MERCHANT_APPROVE_REFUND: "商户同意退款申请",
      MERCHANT_REFUSE_RERUND: "商户拒绝退款申请",
      USER_SUBMIT_SATISFACTION:
        "用户提交满意度调查结果,此时操作内容里会展示满意度分数",
    };
    return map[type];
  }

  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  handleRemove(file: any) {
    const index = this.fileList.indexOf(file);
    const newFileList = this.fileList.slice();
    newFileList.splice(index, 1);
    this.fileList = newFileList;

    this.imageList = this.imageList.filter(
      (img: any) => img.filename !== file.name
    );
    // console.log(this.fileList);
    // console.log(this.imageList);
  }

  async beforeUpload(file: File) {
    this.getBase64(file).then((url) => {
      this.fileList = [
        ...this.fileList,
        {
          uid: this.fileList.length,
          name: file.name,
          status: "uploading",
          url,
        },
      ];
    });
    return false;
  }

  async uploadImage(file: File) {
    const mchid = this.handleItem.mchid;
    this.confirmDisabled = true;
    this.historyModalSpinning = true;
    const res: any = await imageUpload(mchid, file);
    this.confirmDisabled = false;
    this.historyModalSpinning = false;
    // console.log(res);
    if (res.data.code === 200) {
      const wxRes = res.data.data;
      if (wxRes.code === 200) {
        const media_id = wxRes.message.media_id;
        this.imageList.push({
          filename: file.name,
          media_id: media_id,
        });
        // console.log(this.imageList);
        this.$message.success("图片上传成功");
      } else {
        this.$message.error(wxRes.message.message);
        this.handleRemove(file);
      }
    } else {
      this.$message.error("服务器请求失败");
      this.handleRemove(file);
    }
  }

  // 自定义上传
  async customRequest(option: any) {
    await this.uploadImage(option.file);
    option.onSuccess();
  }

  // 提交
  async handleOk() {
    if (!this.reply_text) {
      this.$message.error("请输入回复内容");
      return;
    }
    // console.log(this.imageList);
    // console.log(this.handleItem);
    this.confirmLoading = true;

    const response_images = this.imageList.map((img: any) => img.media_id);

    // console.log(response_images);

    const res: any = await complaintResponse(
      this.handleItem.complaint_id,
      this.handleItem.mchid,
      this.reply_text,
      response_images
    );
    // console.log(res);
    if (res.data.code === 200 && res.data.data.code === 204) {
      this.getComplainsList();
      this.handleModal = false;
      this.$message.success("回复成功");
    } else {
      this.$message.error("请求错误");
    }

    this.confirmLoading = false;
  }

  // 详情中回复
  async handleOkInHistory() {
    if (!this.reply_text) {
      this.$message.error("请输入回复内容");
      return;
    }
    // console.log(this.imageList);
    // console.log(this.handleItem);
    this.historyModalSpinning = true;

    const response_images = this.imageList.map((img: any) => img.media_id);

    // console.log(response_images);

    const res: any = await complaintResponse(
      this.handleItem.complaint_id,
      this.handleItem.mchid,
      this.reply_text,
      response_images
    );
    // console.log(res);
    if (res.data.code === 200 && res.data.data.code === 204) {
      this.getComplainsList();
      this.historyModal = false;
      this.$message.success("回复成功");
    } else {
      this.$message.error("请求错误");
    }

    this.historyModalSpinning = false;
  }

  // 处理完成
  complaintComplete(record: any) {
    // console.log(record);
    this.$confirm({
      title: "确定要处理完成吗?",
      okText: "确定",
      cancelText: "取消",
      onOk: async () => {
        const res: any = await complaintComplete(
          record.complaint_id,
          record.mchid
        );
        if (res.data.code === 200) {
          this.$message.success("处理成功");
          this.historyModal = false;
          this.getComplainsList();
        } else {
          this.$message.error("服务器异常");
        }
      },
    });
  }

  // 预览图片
  previewImg(images: string[], index: number) {
    const viewer = this.$viewerApi({
      images: [...images],
    });
    viewer.update();
  }
}
